import React from "react"
import useInstitutionQuery from "../../hooks/useInstitutionQuery"

export default function PrimaryTable(props) {
  const { headers } = props
  const institutions = useInstitutionQuery()
  return (
    <div className="flex flex-col">
      <div className="py-2 px-3 lg:px-0">
        <div className=" overflow-x-auto shadow-tableShadow sm:rounded-lg">
          <table className="w-full">
            <thead className={"bg-blue"}>
              <tr>
                {headers &&
                  headers.map((header, index) => {
                    return index > 0 ? (
                      <th
                        key={index}
                        scope="col"
                        className={
                          "px-3 py-3 text-left  uppercase tracking-wider text-white md:px-6 "
                        }
                      >
                        <h3>{header.title}</h3>
                      </th>
                    ) : (
                      <th
                        key={index}
                        scope="col"
                        className={
                          "px-3 py-3 text-left uppercase tracking-wider text-white md:px-6 "
                        }
                      >
                        <h3>{header.title}</h3>
                      </th>
                    )
                  })}
              </tr>
            </thead>
            <tbody>
              {institutions.nodes.map((institution, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-white" : "bg-logoBG"}
                >
                  <td className="text-sm px-3 py-4 font-robotoCondensed text-pTable  text-gray900 md:px-6">
                    {institution.contact.name}
                  </td>

                  {institution.contact.address && (
                    <td className="text-sm text-gray-500  px-3 py-4 text-center md:px-6">
                      <p className=" mx-auto text-left text-pTable">
                        {institution.contact.address}
                      </p>
                    </td>
                  )}

                  {institution.contact.date && (
                    <td className="text-sm text-gray-500  px-3 py-4 text-center md:px-6">
                      <p className=" mx-auto text-left text-pTable">
                        {institution.contact.date}
                      </p>
                    </td>
                  )}
                  {institution.contact.director && (
                    <td className="text-sm text-gray-500  px-3 py-4 text-center md:px-6">
                      <p className=" mx-auto text-left text-pTable">
                        {institution.contact.director}
                      </p>
                    </td>
                  )}

                  {institution.contact.jobTitle && (
                    <td className="text-sm text-gray-500  px-3 py-4 text-center md:px-6">
                      <p className=" mx-auto text-left text-pTable">
                        {institution.contact.jobTitle}
                      </p>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
