/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import useInstitutionQuery from "../../hooks/useInstitutionQuery"

export default function MobileTable(props) {
  const { headers } = props
  const institutions = useInstitutionQuery()
  return (
    <div className="flex flex-col px-[20px]">
      <div className="-my-2 mb-[32px]">
        <div className="py-2 align-middle">
          <div className="rounded-lg shadow-tableShadow overflow-x-auto">
            <table className="min-w-full block md:hidden">
              {institutions.nodes.map((institution, index) => (
                <tbody
                  key={index}
                  className={
                    index % 2 === 0
                      ? "bg-blue flex flex-row"
                      : "bg-darkBlue flex flex-row"
                  }
                >
                  <tr>
                    {headers &&
                      headers.map((header, index) => {
                        return index > 0 ? (
                          <th
                            key={index}
                            scope="col"
                            className={
                              "px-2 py-3 text-right text-white uppercase tracking-wider h-[47px] flex flex-col "
                            }
                          >
                            <h3 className="my-auto">{header.title}</h3>
                          </th>
                        ) : (
                          <th
                            key={index}
                            scope="col"
                            className={
                              "px-3 py-3 text-right text-white uppercase tracking-wider h-[47px] flex flex-col"
                            }
                          >
                            <h3 className="my-auto">{header.title}</h3>
                          </th>
                        )
                      })}
                  </tr>

                  <tr
                    key={index}
                    className={
                      index % 2 === 0
                        ? "bg-white flex flex-col flex-1"
                        : "bg-logoBG flex flex-col flex-1"
                    }
                  >
                    <td className="font-robotoCondensed text-pTable px-3 py-4 h-[47px] text-sm text-gray900">
                      {institution.contact.name}
                    </td>

                    {institution.contact.address && (
                      <td className="px-3 py-4 h-[47px] text-sm text-gray-500 flex items-center">
                        <p className=" text-pTable text-left">
                          {institution.contact.address}
                        </p>
                      </td>
                    )}

                    {institution.contact.director && (
                      <td className="px-3 py-4 h-[47px] text-sm text-gray-500">
                        <p className=" text-pTable text-left">
                          {institution.contact.director}
                        </p>
                      </td>
                    )}

                    {institution.contact.jobTitle && (
                      <td className="px-3 py-4 h-[47px] text-sm text-gray-500">
                        <p className=" text-pTable text-left">
                          {institution.contact.jobTitle}
                        </p>
                      </td>
                    )}
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
