import React from "react";

import About from "../components/tables/About";
import AboutMobile from "../components/tables/AboutMobile";

import Logo from "../images/icons/logo.svg";
import Edges from "../components/Edges";
import PageHeader from "../components/PageHeader";

import Seo from "../components/Seo";

const AboutPage = (props) => {
    const { uri } = props;

    const tableHeaders = [{ title: "Institution" }, { title: "Address" }, { title: "Directors" }, { title: "Title" }];

    const pageTitle = "About";
    const BctapUrl = "https://www2.gov.bc.ca/gov/content/governments/services-for-government/public-sector-management/plan-report/accountabilities";

    return (
        <>
            <Seo title={pageTitle} description="Trades Training BC is a consortium of 15 public post-secondary educational institutions created to promote trades training in British Columbia." />

            <PageHeader title="About Trades Training BC" breadcrumbs={[{ label: pageTitle, url: uri }]} />

            <Edges size="md">
                <div className="flex flex-col justify-center px-[20px] md:flex-row md:px-[0px]">
                    <div className="mr-[30px] w-[130px] min-w-[130px]">
                        <Logo className="mb-[20px]" />
                    </div>
                    <div className="text-[#414141] lg:w-11/12">
                        <p className="mb-[20px] ">
                            Trades Training BC is a consortium of 15 public post-secondary educational institutions created to promote trades training in British Columbia. Trades Training BC's primary function is to
                            encourage students and others considering career retraining to explore trades as a viable career option. Additionally, Trades Training BC acts as an advocate for the member institutions in
                            their communication with the public, government, businesses and other stakeholders. The consortium adheres to{" "}
                            <a href={BctapUrl} target={"_blank"} className="text-blue">
                                BC Taxpayer Accountability Principles
                            </a>
                            .
                        </p>
                        <p className="mb-[20px]">
                            TradesTrainingBC.ca is a portal created by the consortium to provide current trades training schedules while directing students and employers to Apprenticeship, Foundation, Technical, and
                            Youth programs offered at the member institutions throughout BC. Program sessions are listed chronologically by level (if appropriate) within various BC regions. Each institution's program
                            contains a mail icon that allows you to contact the trainer directly about a particular session. Note that program registration is handled on each institution's own website, not from this web
                            site.
                        </p>
                    </div>
                </div>
                <div className="flex-start flex min-w-0 flex-col py-[51px]">
                    <h2 className="mx-auto mb-[38px] px-[8px] text-center sm:text-left">Current member institutions of the Consortium</h2>

                    <div className="hidden sm:block">
                        <About uri={uri} headers={tableHeaders} />
                    </div>
                    <div className="block sm:hidden">
                        <AboutMobile uri={uri} headers={tableHeaders} />
                    </div>
                </div>
            </Edges>
        </>
    );
};

export default AboutPage;
